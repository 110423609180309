<template>
  <div class="test-plans-placeholder">
    <div class="test-plans-placeholder-wrapper">
      <imgBanner />
      <div class="title mt-6">{{ $t('plans.placeholder.title') }}</div>
      <div class="description">{{ $t('plans.placeholder.description') }}</div>
      <div class="w-100 flex">
        <v-btn class="btn-create-plans" color="#0C2FF3" dark depressed :to="{ name: 'TestPlanCreate' }">
          {{ $t('plans.createTestPlan') }}
          <v-icon class="ml-1" size="xs">mdi-plus</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import imgBanner from '@/assets/svg/test-plans-placeholder.svg';
export default {
  name: 'Placeholder',

  components: {
    imgBanner,
  },
};
</script>

<style lang="scss" scoped>
.test-plans-placeholder {
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .test-plans-placeholder-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .title {
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: #18181a;
  }

  .description {
    margin-top: 12px;
    max-width: 400px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #323236;
  }

  .btn-create-plans {
    margin-top: 24px;
    height: 40px;
    text-transform: none;
  }
}
</style>