import { render, staticRenderFns } from "./Placeholder.vue?vue&type=template&id=4468cd98&scoped=true"
import script from "./Placeholder.vue?vue&type=script&lang=js"
export * from "./Placeholder.vue?vue&type=script&lang=js"
import style0 from "./Placeholder.vue?vue&type=style&index=0&id=4468cd98&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4468cd98",
  null
  
)

export default component.exports